<script>
  import screen from '@/helpers/screen'

  /**
   * A component that displays promotional content with optional image as background and overlaying text. Takes the full height of the parent component.
   */
  export default {
    props: {
      /** The size of the banner. */
      size: {
        type: String,
        required: true,
        validator: (value) => ['large', 'medium-full', 'medium-half', 'small'].includes(value),
      },
      /** The text color. */
      textColor: {
        type: String,
        default: 'black',
        validator: (value) => ['white', 'black', 'dawn'].includes(value),
      },
      /** Position of the text. */
      textPosition: {
        type: String,
        default: 'center',
        validator: (value) => ['left', 'center'].includes(value),
      },
      /** Width size of the content. */
      contentWidth: {
        type: String,
        default: 'full',
        validator: (value) => ['half', 'full'].includes(value),
      },
      /** What the background color should be if a button is used instead of text for the CTA. */
      ctaButtonColor: {
        type: String,
        default: undefined,
        validator: (value) => ['primary', 'sunshine', 'dawn', 'black', 'summer'].includes(value),
      },
      /** What the display property of the banner should be. */
      display: {
        type: String,
        default: 'flex',
        validator: (value) => ['flex', 'grid'].includes(value),
      },
      /** What the flex layout of the banner should be if display is 'flex'. */
      flexLayout: {
        type: String,
        default: 'row',
        validator: (value) => ['row', 'row-reverse', 'col', 'col-reverse'].includes(value),
      },
      /** add extra classes to text container */
      textClasses: {
        type: String,
        default: null,
      },
    },
    computed: {
      screen,
      mainCopyClass() {
        switch (this.size) {
          case 'medium-half':
            return 'text-xs'
          default:
            return 'text-sm'
        }
      },
      titleClass() {
        switch (this.size) {
          case 'large':
            return 'text-4xl'
          case 'small':
            return 'text-xl lg:text-3xl'
          default:
            return 'text-xxl'
        }
      },
    },
  }
</script>

<template>
  <!-- for purgecss: flex grid flex-row flex-col-reverse flex-col flex-row-reverse -->
  <div class="h-full flex flex-col justify-between">
    <div
      :class="`${display} ${display === 'grid' ? 'grid-cols-2' : ''} flex-${
        screen.md || flexLayout === 'col-reverse' ? flexLayout : 'col'
      } ${
        textPosition === 'center' || flexLayout === 'col-reverse' ? 'justify-center' : ''
      } items-center h-full`"
    >
      <!-- for purgecss: text-white text-black text-dawn -->
      <div
        :class="`z-10 mx-3 md:mb-1 md:mx-0 p-4 ${
          size === 'large' && contentWidth === 'full' ? 'xl:px-8' : ''
        } ${contentWidth === 'half' ? 'min-w-82 max-w-82 pl-8' : ''} ${
          textPosition === 'center' ? 'text-center' : ''
        } text-${textColor} ${textClasses}`"
      >
        <div class="font-heading text-lg" style="line-height: 1.2">
          <!-- @slot Optional. A header above the title. Should contain text. -->
          <slot name="header" />
        </div>
        <div
          :class="`font-heading leading-none py-3 ${titleClass} ${
            textColor === 'black' ? 'font-medium' : ''
          }`"
        >
          <!-- @slot The banner’s title. Should contain text. -->
          <slot />
        </div>
        <p
          :class="`mb-4 leading-snug-tight ${mainCopyClass} ${
            textColor === 'dawn' ? 'font-light' : ''
          }`"
        >
          <!-- @slot Optional. Main copy for the banner. Should contain text. -->
          <slot name="description" />
        </p>
        <template v-if="$scopedSlots.cta">
          <div
            v-if="!ctaButtonColor"
            :class="`font-semibold ${size === 'large' ? 'text-lg' : 'text-base'}`"
          >
            <!-- @slot Optional. Call to action. Should contain text. -->
            <slot name="cta" />
            <BaseIcon :size="4" class="inline-block align-middle">
              <IconChevronRight />
            </BaseIcon>
          </div>
          <div v-else class="pt-2 pb-1 md:pb-0">
            <!-- for purgecss: bg-primary bg-sunshine bg-dawn bg-black -->
            <div
              :class="`bg-${ctaButtonColor} inline-block px-6 py-10px font-medium text-base ${
                ctaButtonColor === 'primary' || ctaButtonColor === 'black'
                  ? 'text-white'
                  : 'text-black'
              }`"
            >
              <span class="truncate">
                <slot name="cta" />
              </span>
            </div>
          </div>
        </template>
      </div>
      <!-- @slot Optional. Divider before the image. Should contain a `BaseDivider` component. -->
      <slot name="divider" />
      <!-- @slot Optional. Image. Should contain a `BaseImage` component. -->
      <slot name="image" />
    </div>
    <template v-if="$scopedSlots.bottomBanner">
      <div
        class="w-full pt-6 lg:pt-21px pb-22px lg:pb-4 flex items-center justify-center bg-dawn font-heading text-xl xl:text-2xl font-medium text-black"
      >
        <slot name="bottomBanner" />
      </div>
    </template>
  </div>
</template>
